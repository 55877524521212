import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import {
  // Appointment,
  // Calendly,
  Dashboard,
  // LawyerDetails,
  // LawyerList,
  SignUp,
  SignIn,
  Home,
  HistoryDashboard,
  LandingPage,
} from "../components";
import CheckAuth from "../components/util/CheckAuth";
import Guest from "../components/util/Guest";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      {
        path: "/home",
        element: (
          <CheckAuth>
            <Home />
          </CheckAuth>
        ),
        children: [
          {
            path: "/home",
            element: <Dashboard />,
          },

          // {
          //   path: "/home/lawyers",
          //   element: <LawyerList />,
          // },
          // {
          //   path: "/home/appointment",
          //   element: <Appointment />,
          // },
          // {
          //   path: "/home/lawyer/:id",
          //   element: <LawyerDetails />,
          // },

          // {
          //   path: "/home/schedule-appointment",
          //   element: <Calendly />,
          // },
          {
            path: "/home/chat/:sessionId",
            element: <HistoryDashboard />,
          },
        ],
      },
      {
        path: "/register",
        element: (
          <Guest>
            <SignUp />
          </Guest>
        ),
      },
      {
        path: "/signin",
        element: (
          <Guest>
            <SignIn />
          </Guest>
        ),
      },
      {
        path: "/",
        element: <LandingPage />,
      },
    ],
  },
]);

export default router;
