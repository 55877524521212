import dislike from "./solar_dislike-bold-1.svg";
import like from "./solar_dislike-bold.svg";
import edit from "./tabler_edit.svg";
import logout from "./solar_logout-3-linear.svg";
import copy from "./mingcute_copy-fill.svg";
import speaker from "./material-symbols_select-to-speak-rounded.svg";
// import refresh from "./material-symbols_refresh-rounded.svg";
import arrowDown from "./material-symbols_keyboard-arrow-down-rounded.svg";
import arrowForward from "./material-symbols_arrow-forward-ios-rounded.svg";
import arrowBack from "./material-symbols_arrow-back-ios-new-rounded.svg";

import logo1 from "./Logo-1.svg";
import logo from "./farmo_logo.svg";
import lawyerDirectory from "./Lawyer_Directory.svg";

import dots from "./dots.svg";
import dashboard from "./Dashboard.svg";
import calendar from "./material-symbols_calendar-month-outline.svg";
import close from "./ic_round-close.svg";
import cancel from "./iconoir_cancel.svg";

import landingBg from "./landingPageMatrixBG.png";

import landing1 from "./landingpageimg1.png";

import landingCardIcon1 from "./landingcard1.png";
import landingCardIcon2 from "./landingcard2.png";
import landingCardIcon3 from "./landingcard3.png";
import landingStarIcon from "./landingStarIcon.png";
import landingPageAILogo from "./landingPageAILogo.png";
import landingSignupIllustration from "./landingSignupIllustration.png";
import landingpageFeaturePattern from "./landingpageFeaturePattern.png";
import landingpageFeatureImg from "./landingpageFeatureImg.png";
import landingpagePerfectForImg from "./landingpagePerfectForImg.png";
import LandingperfectForIcon1 from "./LandingperfectForIcon1.png";
import LandingperfectForIcon2 from "./LandingperfectForIcon2.png";
import LandingperfectForIcon3 from "./LandingperfectForIcon3.png";
import FarmoLeaf from "./farmo_leaf.png";
//

export {
  like,
  dislike,
  edit,
  logout,
  copy,
  speaker,
  arrowDown,
  arrowForward,
  logo,
  logo1,
  lawyerDirectory,
  dots,
  dashboard,
  calendar,
  close,
  cancel,
  arrowBack,
  landing1,
  landingBg,
  landingCardIcon1,
  landingCardIcon2,
  landingCardIcon3,
  landingStarIcon,
  landingPageAILogo,
  landingSignupIllustration,
  landingpageFeaturePattern,
  landingpageFeatureImg,
  landingpagePerfectForImg,
  LandingperfectForIcon1,
  LandingperfectForIcon2,
  LandingperfectForIcon3,
  FarmoLeaf,
};
