import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaUser } from "react-icons/fa";
import logo from "../assets/logo.png";
import { copy, speaker } from "../assets";

const ChatBox = ({ chat1 }) => {
  const [speechSpeaking, setSpeechSpeaking] = useState(false);

  useEffect(
    () => () => {
      window.speechSynthesis.cancel();
      setSpeechSpeaking(false);
    },
    []
  );

  const handleSpeaker = (text) => {
    if (speechSpeaking) {
      window.speechSynthesis.cancel();
      setSpeechSpeaking(false);
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.onend = () => {
        setSpeechSpeaking(false);
      };
      window.speechSynthesis.speak(msg);
      setSpeechSpeaking(true);
    }
  };

  const handleCopy = () => {
    let text;
    if (chat1 && chat1.response.message) {
      text = chat1.response.message;
    }
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      {chat1 && (
        <div className="chat">
          <div className="user-chat">
            <FaUser size={32} className="p-1 rounded-full bg-slate-700" />

            <div className="query">{chat1.userQuery}</div>
          </div>

          <div className="bot-chat">
            <div className="bot-top-chat">
              <img
                className="w-8 p-0.5 object-contain rounded-full bg-slate-400"
                src={logo}
                alt="bot"
              />
              {chat1.response.message && (
                <div className="response">{chat1.response.message}</div>
              )}

              <div className="options flex flex-col md:gap-4 gap-3">
                <img
                  className="icons"
                  src={speaker}
                  alt="speaker-icons"
                  onClick={() => handleSpeaker(chat1.response.message)}
                />
                <img
                  className="icons"
                  src={copy}
                  alt="copy-icons"
                  onClick={handleCopy}
                />
              </div>
            </div>

            {/* {!chatsLoading && !showRecommend ? (
              <button
                type="button"
                className="recommend-btn text-sm py-1 my-4 max-w-max"
                onClick={handleRecommendLawyer}
              >
                {loading ? (
                  <BeatLoader
                    className="flex justify-center items-center"
                    color="red"
                    loading={loading}
                    size={8}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Recommend Lawyer"
                )}
              </button>
            ) : (
              <div className="lawyer-card-wrapper">
                {recommends.map((lawyer) => (
                  <LawyerProfileCard key={lawyer.lawyer_id} {...lawyer} />
                ))}
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

ChatBox.propTypes = {
  chat1: PropTypes.object,
};
ChatBox.defaultProps = {
  chat1: "",
};

export default ChatBox;
