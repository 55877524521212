import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import {
  landing1,
  landingPageAILogo,
  logo,
  FarmoLeaf,
  // LandingperfectForIcon2,
  // LandingperfectForIcon3,
  // landingStarIcon,
} from "../assets";
import TryCourtGPTDashboard from "./TryCourtGPTDashboard";

const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const [connect, setConnect] = useState(false);

  const navigate = useNavigate();

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleConenctWithWebsocket = () => {
    setConnect(true);
  };

  return (
    <div className="landingPage-wrapper">
      <nav className="landing-nav ">
        <img
          src={logo}
          className="md:w-36 w-28 mx-1 cursor-pointer filter-logo"
          alt="logo"
          onClick={handleScrollTop}
        />
        {/* <ul className="sm:flex hidden md:gap-6 gap-4">
          <li onClick={handleScrollTop}> Home </li>
          <li>
            <a href="#feature-sec">Features</a>
          </li>
        </ul> */}

        <button
          className="landing-login-btn "
          type="button"
          onClick={() => navigate("/signin")}
        >
          Sign in
        </button>

        <FaBars
          size={22}
          className="sm:hidden flex cursor-pointer mx-1"
          color="#E9FE53"
          onClick={() => setOpen(!open)}
        />
        {open && (
          <div className="nav-dropdown">
            {/* <span className="lists" onClick={handleScrollTop}>
              Home
            </span>
            <span className="lists">
              <a href="#feature-sec">Features </a>
            </span> */}
            <button
              className="landing-login-btn-mobile "
              type="button"
              onClick={() => navigate("/signin")}
            >
              Sign in
            </button>
          </div>
        )}
      </nav>
      <div className="hero">
        <div className="hero-title">
          <h2 className="sm:text-3xl text-2xl font-bold ">
            Revolutionising Agriculture with AI
          </h2>
          <p className="text-sm sm:text-base text-light  ">
            From Fields to the Future - Join the AI Agricultural Revolution{" "}
            <br />
            with Farmo and elevate your yield, step by step
          </p>
          <a href="#try-it" className="w-max">
            <button className="tryNow-btn" type="button">
              Try it Now
            </button>
          </a>
        </div>
        <div className="hero-img-wrapper">
          <img
            // className="lg:h-[500px] md:h-[420px] sm:h-[300px] h-full"
            src={landing1}
            alt="landing-img"
          />
        </div>

        {/* <div className="matrix-bg">
          <img className="matrix-bg-img" src={landingBg} alt="matrix-bg" />
        </div> */}
      </div>

      <div className="try-live-section" id="try-it">
        <h2 className=" sm:text-2xl text-xl font-semibold ">Try it Live</h2>
        <p className="text-light text-sm ">
          We have trained this Chatbot to answer questions about Farmo.ai.
          <br />
          Ask question to get started with Farmo.ai.
        </p>

        <div className="try-gpt-box max-w-6xl sm:w-90% w-[100%] min-h-[50vh]">
          {!connect && (
            <div className="try-connect-wrapper">
              <button
                className="try-connect-btn"
                type="button"
                onClick={handleConenctWithWebsocket}
              >
                Connect
              </button>
            </div>
          )}

          <TryCourtGPTDashboard connect={connect} />
        </div>
      </div>

      <div className="perfect-for-section">
        <h2 className="font-semibold sm:text-2xl text-xl text-center my-3">
          Our Services
        </h2>

        <div className="perfect-for-box flex-wrap items-center relative">
          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={FarmoLeaf}
                alt="FarmoLeaf"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">
              Precision Farming
            </h3>

            <span className="text-para">
              Analyze soil conditions, monitor crop health and allocate
              resources such as fertilizers & pesticides.
            </span>
            {/* <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient Farming research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span> */}
          </div>
          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={FarmoLeaf}
                alt="FarmoLeaf"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">
              Crop Monitoring
            </h3>

            <span className="text-para">
              Identify diseases, pests, or nutrient deficiencies early on,
              allowing for interventions & reducing crop losses.
            </span>
            {/* <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient Farming research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span> */}
          </div>
          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={FarmoLeaf}
                alt="FarmoLeaf"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">
              Supply Chain Optimization
            </h3>

            <span className="text-para">
              Minimize wastage, streamline logistics, and ensure fresh, quality
              produce reaches the market efficiently
            </span>
            {/* <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient Farming research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span> */}
          </div>
          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={FarmoLeaf}
                alt="FarmoLeaf"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">
              Smart Irrigation
            </h3>

            <span className="text-para">
              Conserve water, prevent over-or under-irrigation, and promote
              water management
            </span>
            {/* <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient Farming research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span> */}
          </div>

          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={FarmoLeaf}
                alt="FarmoLeaf"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">
              Yield Prediction
            </h3>

            <span className="text-para">
              Make informed decisions about harvest planning, logistics, and
              pricing to optimize profitability.
            </span>
            {/* <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient Farming research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span> */}
          </div>

          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={FarmoLeaf}
                alt="FarmoLeaf"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">
              Farm Automation
            </h3>

            <span className="text-para">
              From autonomous machinery to robotic harvesting systems, we help
              you reduce labor costs.
            </span>
            {/* <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient Farming research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span> */}
          </div>
          <div className="bg-overlay" />
        </div>
      </div>

      {/* <div className="try-gpt-bookDemo">
        <div className="bookDemo-wrapper">
          <div className="try-gpt-title">
            <h2 className="font-semibold sm:text-2xl text-xl">
              Stay Ahead in the Legal Landscape
            </h2>
            <p className="text-para sm:!text-base ">
              Request a demo now to stay ahead of the competition with our
              advanced solution tailored for you!
            </p>
          </div>
          <RequestDemo />
        </div>
      </div> */}

      <div className="separator" />
      <footer className="landing-footer">
        <div className="footer-upper sm:text-base text-sm">
          <img
            src={logo}
            className="md:w-32 w-28 mx-1 filter-logo"
            alt="logo"
            onClick={handleScrollTop}
          />

          <div className="footer-lower">
            <p className="sm:text-base text-sm text-footer">
              Powered by AI Studio
            </p>
            <img
              className="h-6 object-contain"
              src={landingPageAILogo}
              alt="landingPageAILogo"
            />
          </div>

          {/* <ul className="flex items-center md:gap-6 gap-4 ">
            <li onClick={handleScrollTop}> Home </li>
            <li>
              <a href="#feature-sec">Features</a>
            </li>
            <li> Terms </li>
            <li> Privacy policy </li>
          </ul> */}

          <p>© 2023 Farmo.ai</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
