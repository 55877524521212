import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import PropTypes from "prop-types";

import { copy, speaker } from "../assets";
import logo from "../assets/logo.png";

const History = ({ chat }) => {
  const [speechSpeaking, setSpeechSpeaking] = useState(false);

  useEffect(
    () => () => {
      window.speechSynthesis.cancel();
      setSpeechSpeaking(false);
    },
    []
  );

  const handleSpeaker = (text) => {
    if (speechSpeaking) {
      window.speechSynthesis.cancel();
      setSpeechSpeaking(false);
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.onend = () => {
        setSpeechSpeaking(false);
      };
      window.speechSynthesis.speak(msg);
      setSpeechSpeaking(true);
    }
  };

  const handleCopy = (message) => {
    let text;
    if (message) {
      text = message;
    }
    navigator.clipboard.writeText(text);
  };

  // const handleRecommendLawyer = (userMessage) => {
  //   async function fetchRecommendedLawyer(userQuery) {
  //     setLoading(true);
  //     const { data } = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/recommend`,
  //       {
  //         query: userQuery,
  //       }
  //     );
  //     setRecommends(data);
  //     setLoading(false);
  //     setShowRecommend(true);
  //   }
  //   fetchRecommendedLawyer(userMessage);
  // };

  return (
    <div className="chat-history">
      {chat.user[0].user_type === "USER" ? (
        <div className="user-history-chat">
          <FaUser size={32} className="p-1 rounded-full bg-slate-700" />
          <div className="query">{chat.message}</div>
        </div>
      ) : (
        <div className="bot-chathistory-wrapper">
          <div className="bot-history-chat">
            <img
              className="w-8 p-0.5 object-contain rounded-full bg-slate-400"
              src={logo}
              alt="bot"
            />
            {chat.message && <div className="response">{chat.message}</div>}

            <div className="options flex flex-col md:gap-4 gap-3">
              {/* <img className="icons" src={like} alt="like-icons" />
      <img className="icons" src={dislike} alt="dislike-icons" /> */}
              <img
                className="icons"
                src={speaker}
                alt="speaker-icons"
                onClick={() => handleSpeaker(chat.message)}
              />
              <img
                className="icons"
                src={copy}
                alt="copy-icons"
                onClick={() => handleCopy(chat.message)}
              />
            </div>
          </div>
          {/* {!chatsLoading && !showRecommend ? (
            <button
              type="button"
              className="recommend-btn text-sm py-1 my-4 max-w-max"
              onClick={() => handleRecommendLawyer(chat.message)}
            >
              {loading ? (
                <BeatLoader
                  className="flex justify-center items-center"
                  color="red"
                  loading={loading}
                  size={8}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "Recommend Lawyer"
              )}
            </button>
          ) : (
            <div className="lawyer-card-wrapper">
              {recommends.map((lawyer) => (
                <LawyerProfileCard key={lawyer.lawyer_id} {...lawyer} />
              ))}
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};
History.propTypes = {
  chat: PropTypes.object,
};
History.defaultProps = {
  chat: "",
};

export default History;
