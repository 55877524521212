import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";

import RecentChats from "./RecentChats";
import { clearChat } from "../features/chatSlice";
import {
  dots,
  // calendar,
  dashboard,
  // lawyerDirectory,
  logout,
  logo,
} from "../assets";
import { logOut, storeSession } from "../features/authSlice";

const Sidebar = () => {
  const [dropUp, setDropUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state) => state);

  const { chatSlice, authSlice } = store;

  const { recentChat } = chatSlice;
  const { user } = authSlice;

  const showDropUp = () => {
    setDropUp(!dropUp);
  };

  const handleNewChat = () => {
    const createSession = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      };

      const session_name = "test";

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?session_name=${session_name}`,
          {},
          { headers }
        );

        if (response.statusText === "OK") {
          const { session_id } = response.data.data;
          dispatch(storeSession(session_id));
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            toast.error(`${error.response.data.errorData.message}`);
          } else if (error.response.status === 401) {
            toast.error(`${error.response.data.errorData.message}`);
          } else {
            toast.error(`${error.response.data.errorData.message}`);
          }
        } else if (error.request) {
          toast.error(`No Response received`);
        } else {
          toast.error(`No Response received`);
        }
      }
    };

    createSession();

    navigate("/home");
    dispatch(clearChat());
  };

  const handleLogout = () => {
    dispatch(logOut());
    toast.success("Signed-out Successfully!!!");

    navigate("/");
  };

  return (
    <header className="sidebar-wrapper">
      <div className="sidebar-top">
        <div className="sidebar-upper flxCol s-p border-b-[1px] border-light">
          <div
            className="flex items-center gap-2 mb-2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logo}
              className="md:w-36 w-28 mx-1 filter-logo"
              alt="logo"
            />
          </div>
          <ul>
            <li className="nav-item" onClick={() => navigate("/home")}>
              <img
                className="icons sidebar-icons "
                src={dashboard}
                alt="icon"
              />

              <h3 className="nav-text">Dashboard</h3>
            </li>
            {/* <li className="nav-item" onClick={() => navigate("/home/lawyers")}>
              <img
                className="icons sidebar-icons"
                src={lawyerDirectory}
                alt="icon"
              />
              <h3 className="nav-text">Lawyer Directory</h3>
            </li>
            <li
              className="nav-item"
              onClick={() => navigate("/home/appointment")}
            >
              <img className="icons sidebar-icons" src={calendar} alt="icon" />

              <h3 className="nav-text">Appointments</h3>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        {recentChat.length > 0 && (
          <div className="sidebar-mid flxCol s-p ">
            <span className="flex justify-between items-center cursor-pointer">
              <h2 className=" font-semibold text-primary">Recent Chats</h2>
              {/* <img className="icons sidebar-icons" src={arrowDown} alt="icon" /> */}
            </span>
            <RecentChats />
          </div>
        )}
      </div>
      <div className="sidebar-lower flxCol s-p border-t-[1px] border-light">
        <button type="button" className="new-chat-btn" onClick={handleNewChat}>
          + New Chat
        </button>
        <div className="user-card">
          {/* <FaUser size={32} className="p-1 rounded-full bg-slate-700" /> */}
          <div className="w-8 h-8 rounded-full grid place-content-center p-1 bg-slate-600 uppercase">
            {user.userName && user.userName.split("@")[0][0]}
          </div>
          <div className="user-detail w-[65%] truncate">
            {/* <h2 className=" font-bold">Aditya Puri</h2> */}
            <h3 className="text-light text-sm text-center user-name-sidebar">
              {user.userName && user.userName.split("@")[0]}
            </h3>
          </div>

          {dropUp && (
            <div className="drop">
              <img className="sidebar-icons" src={logout} alt="logout" />
              <button
                type="button"
                className="logout-btn"
                onClick={handleLogout}
              >
                Sign out
              </button>
            </div>
          )}
          <img
            className="icons sidebar-icons"
            src={dots}
            alt="icon"
            onClick={showDropUp}
          />
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
